import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0];

export const dictionary = {
		"/admin": [11,[3]],
		"/admin/internal/actionTypes": [12,[3]],
		"/admin/internal/actionTypes/create": [13,[3]],
		"/admin/internal/actionTypes/edit/[actionTypeId]": [14,[3]],
		"/admin/internal/assetTypes": [15,[3]],
		"/admin/internal/assetTypes/create": [16,[3]],
		"/admin/internal/assetTypes/edit/[assetTypeId]": [17,[3]],
		"/admin/internal/campaigns": [18,[3]],
		"/admin/internal/campaigns/[campaignId]": [19,[3]],
		"/admin/internal/campaigns/[campaignId]/edit": [22,[3]],
		"/admin/internal/campaigns/[campaignId]/[inspectionId]": [20,[3]],
		"/admin/internal/campaigns/[campaignId]/[inspectionId]/edit": [21,[3]],
		"/admin/internal/notices": [23,[3]],
		"/admin/internal/notices/create": [24,[3]],
		"/admin/internal/notices/edit/[noticeId]": [25,[3]],
		"/admin/internal/tenant": [26,[3]],
		"/admin/internal/threadAdmins": [27,[3]],
		"/admin/internal/workflows": [28,[3]],
		"/admin/internal/workflows/create": [29,[3]],
		"/admin/internal/workflows/edit/[workflowId]": [30,[3]],
		"/admin/solution/wind/assetmanagement": [38,[3]],
		"/admin/solution/wind/assetmanagement/create-blade-model": [39,[3]],
		"/admin/solution/wind/assetmanagement/create-turbine-model": [40,[3]],
		"/admin/solution/wind/assetmanagement/register-site": [41,[3]],
		"/admin/solution/wind/assetmanagement/register-turbine": [42,[3]],
		"/admin/solution/wind/assetmanagement/turbine-csv-upload": [43,[3]],
		"/admin/solution/wind/assetmanagement/view-blade-models": [44,[3]],
		"/admin/solution/wind/assetmanagement/view-turbine-models": [45,[3]],
		"/admin/solution/[solution]/annotationLabels": [31,[3,4]],
		"/admin/solution/[solution]/annotationLabels/create": [32,[3,4]],
		"/admin/solution/[solution]/annotationLabels/edit/[annotationLabelID]": [33,[3,4]],
		"/admin/solution/[solution]/csv": [34,[3,4,5]],
		"/admin/solution/[solution]/damageTypes": [35,[3,4]],
		"/admin/solution/[solution]/damageTypes/create": [36,[3,4]],
		"/admin/solution/[solution]/damageTypes/edit/[damageTypeId]": [37,[3,4]],
		"/admin/workspace/applications": [46,[3]],
		"/admin/workspace/assets": [47,[3]],
		"/admin/workspace/assets/create": [49,[3]],
		"/admin/workspace/assets/create/[assetId]": [50,[3]],
		"/admin/workspace/assets/edit/[assetId]": [51,[3]],
		"/admin/workspace/assets/[assetId]": [48,[3]],
		"/admin/workspace/members": [52,[3,6]],
		"/admin/workspace/members/[userId]": [53,[3,6]],
		"/admin/workspace/roles": [54,[3]],
		"/admin/workspace/roles/create": [55,[3]],
		"/admin/workspace/roles/edit/[roleId]": [56,[3]],
		"/admin/workspace/statusDiagram": [57,[3]],
		"/[solution=linearSolution]": [8,[2]],
		"/[solution=linearSolution]/assets": [9,[2]],
		"/[solution=linearSolution]/assets/[assetId=uuid]": [10,[2]],
		"/[...path]": [7]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';